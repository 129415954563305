.circular-content {
    text-align: center;
    display: inline-block;
    width: 25%;
}

.circular-content table {
    margin: auto;
}

/* .circular {
    width: 65px;
    text-align: center;
    padding: 5px;
    border-radius: 100%;
    border: 3px solid hsl(12, 78%, 10%);
    overflow: hidden;
}

.circular img {
    display: block;
    width: 100%;
    height: 100%;
} */


.bad {
    color: #FE4D43;
}

.regular {
    color: #FDDD59;
}

.good {
    color: #B5D855;
}

.excellent {
    color: #79BF5C;
}

.circle-wrap {
    margin: 0px auto;
    width: 68px;
    height: 68px;
    background: #e6e2e7;
    border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
    width: 68px;
    height: 68px;
    position: absolute;
    border-radius: 50%;
}

.circle-wrap .circle .mask {
    clip: rect(-1px, 68px, 68px, 34px);
}

.div-regular .circle-wrap .circle .mask .fill {
    clip: rect(-1px, 34px, 68px, -1px);
    background-color: #FDDD59;
}

.div-good .circle-wrap .circle .mask .fill {
    clip: rect(-1px, 34px, 68px, -1px);
    background-color: #B5D855;
}

.div-excellent .circle-wrap .circle .mask .fill {
    clip: rect(-1px, 34px, 68px, -1px);
    background-color: #79BF5C;
}

.div-bad .circle-wrap .circle .mask .fill {
    clip: rect(-1px, 34px, 68px, -1px);
    background-color: #FE4D43;
}

.p0 .circle-wrap .circle .mask.full,
.p0 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(0deg);
}

.p10 .circle-wrap .circle .mask.full,
.p10 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(18deg);
}

.p20 .circle-wrap .circle .mask.full,
.p20 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(36deg);
}

.p30 .circle-wrap .circle .mask.full,
.p30 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(54deg);
}

.p40 .circle-wrap .circle .mask.full,
.p40 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(72deg);
}

.p50 .circle-wrap .circle .mask.full,
.p50 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(90deg);
}

.p60 .circle-wrap .circle .mask.full,
.p60 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(108deg);
}

.p70 .circle-wrap .circle .mask.full,
.p70 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(126deg);
}

.p80 .circle-wrap .circle .mask.full,
.p80 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(144deg);
}

.p90 .circle-wrap .circle .mask.full,
.p90 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(162deg);
}

.p100 .circle-wrap .circle .mask.full,
.p100 .circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(180deg);
}

@keyframes fill {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.circle-wrap .inside-circle {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background: var(--background);
    line-height: 60px;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    z-index: 10;
    font-weight: 700;
}

.inside-circle img {
    width: 55px;
    height: 60px;
}