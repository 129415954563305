:root {
  --link-color: #149CFF;
  --primary-color: #479FCB;
  --secondary-color: #A6AEC8;
  --secondary-color-rgba: rgba(223, 228, 243, 0.5);
  --background: #FFFFFF;
  --background-secondary: #F5F5F5;
  --background-input: #FFFFFF;
  --primary-text-color: #333;
  --secondary-text-color: #A6AEC8;
  --text-color-btn: #FFFFFF;
  --default-radius: 10px;
  --default-radius-layout: 20px;
  --default-padding: 5px;
  --font-style: normal;
  --box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  --font-family: 'DM Sans';

}

:root[data-theme="dark"] {
  --link-color: #149CFF;
  --primary-color: #479FCB;
  --secondary-color: #A6AEC8;
  --secondary-color-rgba: rgba(166, 174, 200, 0.5);
  --background: #1B1B1B;
  --background-secondary: #000;
  --background-input: rgba(0, 0, 0, 0.5);
  --primary-text-color: #ffffff;
  --secondary-text-color: #A6AEC8;
  --text-color-btn: #FFFFFF;
  --default-radius: 10px;
  --default-radius-layout: 20px;
  --default-padding: 5px;
  --font-style: normal;
}

:root[data-theme="mesc"] {
  --link-color: #149CFF;
  --primary-color: #479FCB;
  --secondary-color: #A6AEC8;
  --secondary-color-rgba: rgba(166, 174, 200, 0.5);
  --background: #000;
  --background-secondary: #1B1B1B;
  --background-input: #FFFFFF;
  --primary-text-color: #ffffff;
  --secondary-color: #A6AEC8;
  --text-color-btn: #FFFFFF;
  --default-radius: 10px;
  --default-radius-layout: 20px;
  --default-padding: 5px;
  --font-style: normal;
}


body,
ul,
li,
p {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

html {
  box-sizing: border-box;
}

.header-container {
  grid-row: 1;
}

.menu-container {
  grid-row: 1/10;
  min-height: 100vh;
}

.fade {
  animation: fade-in-keyframes 1s;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.no-data {
  display: block;
  margin: auto;
  text-align: center;
}

.loading {
  display: block;
  margin: auto;
  text-align: center;
}

.alng-rigth {
  text-align: right;
}

.space-botton {
  margin-bottom: 10px;
}

.space-left {
  margin-left: 10px;
  margin-top: 10px;
}

.card_form {
  padding: 1em;
}

.card_default {
  width: 100%;
  padding: 2em;
  border-radius: 20px;
  background: var(--background) !important;
}

.ant-modal .ant-modal-title{
  padding-top: 20px !important;
}

@media(max-width: 570px) {
  .no-mobile {
    display: none !important;
  }

  .div-logout-content-close {
    bottom: 10px !important;
  }
  .ant-layout-header{
    padding-inline:15px !important
  }
}

.vertical {
  vertical-align: middle;
  display: table-cell;
}

.chart-risk .apexcharts-svg {
  min-height: 110%;
}

@media(max-width: 570px) {
  #system-title {
    display: none;
  }

  .div-logo-content {
    text-align: left
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* @media (max-width: 800px) {

  td,
  th {
    width: auto;
    white-space: nowrap;
    padding-left: 1em;
    padding-right: 1em;
  }
} */

.ant-table-body {
  overflow-x: auto !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  width: 1px;
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar {
  height: .4em; /* altura da scrollbar */
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color-rgba);
  border-radius: var(--default-radius);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}